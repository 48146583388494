<template>
    <div>
        <b-overlay :show="loading" no-wrap></b-overlay>
        <b-row>
            <b-col cols="12" style="overflow-x: auto;">
                <zw-table ref="table"
                          tableName="workflow_history"
                          columnsPrefix="workflow_history.column."
                          base-table="workflow_history"
                          tableFormat="App\Managers\Format\WorkflowHistoryFormat"
                          :items="myProvider"
                          :fields="fields"
                          :filter="filter"
                          :selected="selected"
                          title="common.title.workflow_history"
                          :actions-list="getActions()"
                          :sticky="false"
                          sticky-header="100%"
                >
                    <template #cell(actions)="row">
                        <b-button size="sm"
                                  @click="showHistory(row.item.hash)"
                                  variant="info"
                                  class="mr-2"
                        >
                            <font-awesome-icon icon="eye"/>
                        </b-button>
                    </template>
                </zw-table>
            </b-col>
        </b-row>
    </div>
</template>

<script>
import {mapGetters} from 'vuex'
import commonTable from '@/bundles/erika_common_bundle/mixins/common-table'

export default {
    name: 'WorkflowHistory',
    mixins: [commonTable],
    props: {
        offeringId: {
            type: Number,
        },
    },
    data() {
        return {
            loading: true,
            saveFilters: true,
        }
    },
    mounted() {
        this.shown()
    },
    methods: {
        ...mapGetters('CommonTable', ['getCommonTable']),
        ...mapGetters('Workflow', ['getWorkflowHistory']),
        shown() {
            this.loading = false
        },
        myProvider(ctx) {
            return this.commonProviderFetch(ctx, 'CommonTable/fetchTableData', 'getCommonTable');
        },
        ctxBaseParams() {
            return {
                'url': '/table-data/workflow_history',
                'format': 'App\\Managers\\Format\\WorkflowHistoryFormat'
            }
        },
        getActions() {
            return []
        },
        showHistory(hash) {
            const workflowHistory = this.$store.dispatch('Workflow/fetchWorkflowHistory', hash)
            Promise.all([workflowHistory])
                .then(() => {
                    console.log(this.getWorkflowHistory())
                    this.$root.$children[0].openModal('json-modal', {data: this.getWorkflowHistory()})
                })
                .finally(() => {
                    this.loading = false;
                })
        }
    }
}
</script>