<template>
        <WorkflowHistory/>
</template>

<script>
import {mapGetters} from 'vuex'
import commonSave from '@/bundles/erika_common_bundle/mixins/common-save'
import WorkflowHistory from "@/tables/WorkflowHistory";

export default {
    name: 'Settings-workflow-history',
    components: {WorkflowHistory},
    mixins: [commonSave],
    data() {
        return {}
    },
    methods: {
        shown() {

        },
    },
    mounted() {
        this.shown()
    },
}
</script>